import '../App.css';
import React from 'react';

import { useQuery } from '@apollo/client';
import { QUERY_ABOUT_ME } from '../utils/queries';

const About = () => {

    const { data } = useQuery(QUERY_ABOUT_ME, {variables: {email: "303tacticaltraining@gmail.com"}});
    console.log(data)
    console.log(data?.user.aboutPost[0]);

    let aboutPost = data?.user.aboutPost[0]
    console.log(aboutPost);


    return (

        <div id="content">
        <h2>About</h2>
        <div>
            <h3>{aboutPost}</h3>
        </div>

        </div>

    )
}

export default About;