import '../App.css';
import React from 'react';

import BlogPostList from './components/BlogPostList';

import { useQuery } from '@apollo/client';
import { QUERY_BLOG_POSTS } from '../utils/queries';

const Blog = () => {

    const { loading, data } = useQuery(QUERY_BLOG_POSTS);
    const BlogPosts = data?.blogPosts || [];

    console.log(BlogPosts);

    return (
        <div id="content">
        
        <h2>Blog</h2>

      < p/>

        {loading ? (
            <div>Loading...</div>
          ) : (
            <BlogPostList
              BlogPosts={BlogPosts}
              title="Blog Posts!!!"
            />
          )}


        </div>

    )
}

export default Blog;