import '../App.css';
import React from 'react';


// Import the `useParams()` hook
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';

// import CommentList from '../components/CommentList';
// import CommentForm from '../components/CommentForm';

import { QUERY_BLOG_POST } from '../utils/queries';

const SinglePost = () => {
  // Use `useParams()` to retrieve value of the route parameter `:profileId`
  const { blogPostId } = useParams();
  console.log(blogPostId);

  const { data } = useQuery(QUERY_BLOG_POST, {
    // pass URL parameter
    variables: { blogId: blogPostId },
  });

  const BlogPost = data?.blogPost || {};
  console.log(BlogPost);

  // if (!BlogPost.length) {
  //   return <h3>No Post Found!</h3>;
  // }

  return (
    <div id="content"> 

            {<h3>{BlogPost.titleText}</h3>}
            <h4>
                  <br />
                  <span>
                    Created on {BlogPost.createdAt}
                  </span>
            </h4>

            <div>
              <p>{BlogPost.blogText}</p>
            </div>
          
    

          </div>




  );
};

export default SinglePost;

      /* <div className="my-5">
        <CommentList comments={thought.comments} />
      </div>
      <div className="m-3 p-4" style={{ border: '1px dotted #1a1a1a' }}>
        <CommentForm thoughtId={thought._id} />
      </div> */