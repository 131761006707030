import React from 'react';
import './App.css';
import Home from './pages/Home.js';
import About from './pages/About.js';
import Admin from './pages/Admin.js';
import Blog from './pages/Blog.js';
import Edit from './pages/Edit.js';
import Contact from './pages/Contact.js';
import Navbar from './pages/components/Navbar';
import SinglePost from './pages/SinglePost';

import Auth from './utils/Auth.js';

import { LOGIN_USER } from "./utils/mutations";

import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
  useMutation
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import { gql } from '@apollo/client';

const httpLink = createHttpLink({
  uri: '/graphql',
});

// Construct request middleware that will attach the JWT token to every request as an `authorization` header
const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('id_token');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});


const client = new ApolloClient({
  // Set up our client to execute the `authLink` middleware prior to making the request to our GraphQL API
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});


export const loginFunc = async (email, password) => {
  client
    .mutate({
      mutation: LOGIN_USER, 
      variables: {
        email: email,
        password: password
      }
    })
    .then((response) => { console.log(response);
                        Auth.login();
                        document.location.replace("/edit")})

    .catch((error) => { console.error(error);
                        alert("Incorrect credentials. Try again!") });
  }

// Clear local storage after 2 hours
  var hours = 2; // to clear the localStorage after 1 hour
  // (if someone want to clear after 8hrs simply change hours=8)
  var now = new Date().getTime();
  var setupTime = localStorage.getItem('setupTime');
  if (setupTime == null) {
  localStorage.setItem('setupTime', now)
  } else {
  if(now-setupTime > hours*60*60*1000) {
  localStorage.clear()
  localStorage.setItem('setupTime', now);
  }}



function App() {

  return (
    <ApolloProvider client={client}>
    <Router>
      <>
        <div id="allDiv"></div>
        <Navbar />


        <Routes>
          <Route
            path='/'
            element={<Home />}
          />
          <Route
            path='/about'
            element={<About />}
          />
          <Route
            path='/blog'
            element={<Blog />}
          />          
          <Route
            path='/edit'
            element={<Edit />}
          />     
          <Route
          path='/contact'
          element={<Contact />}
        />
          <Route
            path='/admin'
            element={<Admin />}
          />
          <Route 
          path="/posts/:blogPostId" 
          element={<SinglePost />}
        />


        </Routes>
      
      
      
      </>



    </Router>
    </ApolloProvider>



  );
}

export default App;