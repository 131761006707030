import logo from '../../fulllogo_transparent_nobuffer.png';
import '../../Navbar.css';

function Navbar() {

    return (
        <div id="allDiv">
            <div className="logoDiv">
            <img src={logo} className="App-logo" alt="logo" />
            </div>
            <div className="navbarDiv">
            <div className="Navbar">
             
             
             
             
       
             
             
                <a href="/">
                <div type="button" className="navbarBtn" id="homeBtn" >Home</div></a>
                <a href="/about">
                <div className="navbarBtn" id="aboutBtn">About</div></a>
                <a href="/blog">
                <div className="navbarBtn" id="blogBtn">Blog</div></a>
                <a href="/contact">
                <div className="navbarBtn" id="contactBtn">Contact</div></a>

                
            </div>

        {/* Search Function */}
        </div>
        </div>
    );
}

export default Navbar;
