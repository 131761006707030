import '../App.css';
import React from 'react';
import LogIn from './components/Login.js';


import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";

const client = new ApolloClient({
    // uri: 'process.env.MONGODB_URI',
    // headers: {
    //   'x-hasura-admin-secret': 'MYADMINSECRETKEY'
    // },
    cache: new InMemoryCache()
  });

const Admin = () => {
    return (
        <div id="content">
        


        <ApolloProvider client={client}>
        <LogIn />
         </ApolloProvider>


        </div>



    )
}

export default Admin;