import '../App.css';
import React from 'react';

import About from './About.js';
import Blog from './Blog.js';
import Contact from './Contact.js';

const Home = () => {
    return (
        <div id="homeContent">
        
     

        <About />
        <br />        
        <Blog />
        <br />
        <Contact />
        <br />
        <br />
        <br />
        <br />
        <footer>© 303 Tactical Firearm Fundamentals</footer>

        </div>

    )
}

export default Home;