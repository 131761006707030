import { gql } from '@apollo/client';

export const QUERY_USER = gql`
query User($email: String!) {
    user(email: $email) {
      _id
      email
      password
      blogPosts {
        _id
        titleText
        blogText
        createdAt
        comments {
          _id
          commentText
          commentAuthor
          createdAt
        }
      }
      aboutPost
      contactInfo
    }
  }
`

export const QUERY_ABOUT_ME = gql`
query User($email: String!) {
  user(email: $email) {
    aboutPost
  }
}`

export const QUERY_CONTACT_INFO = gql`
query User($email: String!) {
  user(email: $email) {
    contactInfo
  }
}
`

export const QUERY_BLOG_POST = gql`
query BlogPost($blogId: ID!) {
    blogPost(blogId: $blogId) {
      _id
      titleText
      blogText
      createdAt
      comments {
        _id
        commentText
        commentAuthor
        createdAt
      }
    }
  }
`

export const QUERY_BLOG_POSTS = gql`
query BlogPosts {
    blogPosts {
      _id
      titleText
      blogText
      createdAt
      comments {
        _id
        commentText
        commentAuthor
        createdAt
      }
    }
  }
`