import React, { useState } from 'react';
import '../../App.css';
import { useMutation} from '@apollo/client';
import { LOGIN_USER } from "../../utils/mutations";
import { loginFunc } from "../../App.js";
import Auth from '../../utils/Auth'; 


const Login = () => {
  
    const [ loginState, setLoginState] = useState({
      email:'',
      password:'' 
  });

    const [ errorState, setErrorState ] = useState(true);

//  const [loginUser, {data, error}] = useMutation(LOGIN_USER, {
//     variables: {...loginState},
//     onCompleted: () => console.log(data) })

  const handleChange = (event) => {
    const { name, value } = event.target;

    setLoginState({
      ...loginState,
      [name]: value,
    });
  };

  // const [loginUser] = useMutation(LOGIN_USER, {
  //   variables: {...loginState},
  //   onError: (error) => {console.log(error)},
  //   onCompleted: (data) => { console.log(data) } })

  const [loginUser, {data, error}] = useMutation(LOGIN_USER);


  const handleLogin = async (event) => {

    event.preventDefault();
    console.log(loginState);



  let result = loginFunc(loginState.email, loginState.password);
    console.log(result);
  
 //   if (result === "data.login")


    // setLoginState({
    //   email: '',
    //   password: '',
    // });

  }

     
// let promise1 = new Promise((resolve, reject) => {
    // const {data} = await loginUser({
    //     variables: {...loginState}
    // })
    // console.log(data);


        // .then((result) => console.log(result))
        // .catch((err) => console.error(err));
// })

// let result = await promise1;
// console.log(result);

//    loginUser({
//         variables: {...loginState}
//     })
//         .then((result) => console.log(result))
//         .catch((err) => console.error(err));

//     console.log(data);

// const data = async () => { 
    
// client
//   .mutate({
//     mutation: LOGIN_USER, 
//     variables: {
//         email: loginState.email,
//         password: loginState.password
// }
//   })
//   .then((result) => console.log(result))
//   .catch((err) => console.error(err));
    

// data();

// console.log(data);

    // loginUser({            
    //     variables: {
    //         email: loginState.email,
    //         password: loginState.password
    // }} )
            // .then((result) => console.log(result))
            // .catch((err) => console.error(err))

  //  console.log(results);
   // console.log(error);
        
        // .then((result) => console.log(result))
        // .catch((err) => console.error(err))
        
    


    //  try {
    //     throw new SyntaxError('Hello', 'someFile.js', 18);
    //   } catch (e) {
      
    //     console.log(e.message);  // "Hello"
    //     console.log(e.name);  // "SyntaxError"
    //   }
      

    //   try { 
    //     const { data } = await loginUser({
    //       variables: {
    //         ...loginState       
    //     }
    //   })
    //   Auth.login(data.login.token);
    //   console.log(data);
    //   console.log("Please")
    // } catch (e) {
    //     console.log(e)
    // }

    // try {
    //   const { data } = await loginUser({
    //     variables: {...loginState }
        
    //   })
    //     } catch (error) {
    //        console.log(error);
    //     }

        // const { data } = await loginUser({
        //   variables: {...loginState }
        // });



        
        



    // clear form values


  
    return (
        <div>
          <div>
            <h2>Login</h2>
            <div>

    <form onSubmit={(handleLogin)}>
        <label>
          Email:
          <input type="email" 
          name="email"
          value={loginState.email} 
          placeholder="Your email"
          onChange={handleChange} />
        </label>
        <br />
        <label>
            Password:
            <input type="password" 
            name="password"
            value={loginState.password} 
            placeholder="*******"
            onChange={handleChange} />
        </label>
        <br />
        <button type="submit">Log in</button>
        </form> 
            
        <div id="errorDiv"
             hidden={errorState}>
          Something went wrong! Please try again!
          </div>   
            </div>
          </div>
        </div>

            
    );
  };

  
export default Login;