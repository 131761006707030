import '../App.css';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { QUERY_USER, QUERY_ME } from '../utils/queries';
import { ADD_ABOUT_POST, ADD_BLOG_POST, REMOVE_BLOG_POST, EDIT_BLOG_POST, ADD_CONTACT_INFO  } from '../utils/mutations';

import emailjs from '@emailjs/browser';
import Auth from '../utils/Auth.js';

const Edit = () => {


// Form States

const [aboutState, setAboutState] = useState({ email: '303tacticaltraining@gmail.com', aboutPost: '' });

const [contactState, setContactState] = useState({ email: '303tacticaltraining@gmail.com', contactInfo: '' });

const [addBlogState, setAddBlogState] = useState({ titleText: '', blogText: '' });
// fix blog id
const [editBlogState, setEditBlogState] = useState({blogId: '', titleText: '', blogText: ''});

const [ authDivState, setAuthDivState ] = useState(true);


// Mutation Set Up

  const [changeAbout, { dataA, errorA }] = useMutation(ADD_ABOUT_POST);
  const [addBlog, { dataB, errorB }] = useMutation(ADD_BLOG_POST);
  const [changeContact, { dataC, errorC }] = useMutation(ADD_CONTACT_INFO);
   // const [editBlog, { data, error }] = useMutation(EDIT_BLOG_POST);
   // const [deleteBlog, { data, error }] = useMutation(REMOVE_BLOG_POST);


// Auth Core
window.addEventListener("load", function(){

    let authq = Auth.loggedIn();
    console.log(authq);

    if (authq === false) {
        alert("You don't have sufficient credentials to reach this page!");
        document.location.replace('/')
            } else {
                setAuthDivState(false);
            }
        }   )


// Handle Changes
    const handleAboutChange = (event) => {
        const { name, value } = event.target;

        setAboutState({
        ...aboutState,
        [name]: value,
        });
    };

    const handleAddBlogChange = (event) => {
        const { name, value } = event.target;

        setAddBlogState({
        ...addBlogState,
        [name]: value,
        });
    };

    const handleContactChange = (event) => {
        const { name, value } = event.target;

        setContactState({
        ...contactState,
        [name]: value,
        });
    };


        let templateParams = {
            name: '',
            notes: ''
        };


    
    const submitNewPost = async (event) => {
        event.preventDefault();
        console.log(addBlogState);
        try {
            const { dataBlog } = await addBlog({
                variables: {...addBlogState }
            })
            console.log(dataBlog);
        } catch (e) {
            console.error(e);
        }

        setAddBlogState({
            titleText: '',
            blogText: '',
        });

        emailjs.send('service_xdn2f7l', 'template_enmwgnu', templateParams, '0QGXzKQjG7o3OWha9')
    .then(function(response) {
       console.log('SUCCESS!', response.status, response.text);
    }, function(error) {
       console.log('FAILED...', error);
    });
    
        alert("Success!");
    
    }
    
    const submitAbout = async (event) => {
        event.preventDefault();
        console.log(aboutState);
        try {
            const { dataAbout } = await changeAbout({
                variables: {...aboutState }
            })
            console.log(dataAbout);
        } catch (e) {
            console.error(e);
        }

        setAboutState({
            aboutPost: ''
        });
    
        alert("Success!");
    
    }

    const submitContact = async (event) => {
        event.preventDefault();
        console.log(contactState);
        try {
            const { dataContact } = await changeContact({
                variables: {...contactState }
            })
            console.log(dataContact);
        } catch (e) {
            console.error(e);
        }

        setContactState({
            contactInfo: ''
        });
    
        alert("Success!");
    
    }
    
    const submitForm = () => {
    
    
    
    }







    return (
        <div id="content">
        <div id="authDiv"
             hidden={authDivState}>

        <h2> Edit Page </h2>

        <p />

    Add a Blog Post:
        <form onSubmit={(submitNewPost)}>

            <input type="text" id="fname" name="titleText" value={addBlogState.titleText} onChange={handleAddBlogChange} placeholder="Enter title here..." /> 

        <p />

            <textarea id="freeform" name="blogText" rows="4" cols="50" placeholder='Enter text here...' value={addBlogState.blogText} onChange={handleAddBlogChange} />
                <p />
            <button type="submit"> Submit </button>

        </form>
        <p />

    Change About Information:
        <form onSubmit={(submitAbout)}>

            <textarea id="freeform" name="aboutPost" rows="4" cols="50" placeholder='Enter text here...' value={aboutState.blogText} onChange={handleAboutChange} />
                <p />
            <button type="submit"> Submit </button>

        </form> <p />

    Change Contact Information:
        <form onSubmit={(submitContact)}>

            <textarea id="freeform" name="contactInfo" rows="4" cols="50" placeholder='Enter text here...' value={contactState.contactInfo} onChange={handleContactChange} />
                <p />
            <button type="submit"> Submit </button>

        </form>



        {/* <form onSubmit={(submitForm)}>

            <input type="text" id="fname" name="fname" placeholder="Enter title here..."/> 

            <p />
            
            <textarea id="freeform" name="freeform" rows="4" cols="50" placeholder='Enter text here...' />

            <button type="submit"> Submit </button>

        </form> */}
            

        </div>
        </div>
    )
}

export default Edit;

// On this Page:

// Edit About Page
// Add New Blog Post
// Edit + Delete Blog Post
// contact info