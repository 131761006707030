import React from 'react';
import { Link } from 'react-router-dom';

const BlogPostList = ({
    BlogPosts,
    titleText,
    blogText,
    createdAt
  }) => {
    if (!BlogPosts.length) {
      return <h3>No Posts Yet!</h3>;
    }
  
    return (
      <div>

        {BlogPosts &&
          BlogPosts.map((BlogPost) => (
            <div key={BlogPost._id}>
        {<h5>{BlogPost.titleText}</h5>}
              
              <Link
                to={`/posts/${BlogPost._id}`}>
                <h7>                    
                    <span>
                      Created on {BlogPost.createdAt}
                    </span>
                </h7>
              </Link>

              <div>
                <p>{BlogPost.blogText}</p>
              </div>

              <br /><br /><br />
            </div>
          ))}
      </div>
    );
  };






export default BlogPostList;
