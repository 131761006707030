import { gql } from '@apollo/client';

export const LOGIN_USER = gql`
mutation login($email: String!, $password: String!) {
  login(email: $email, password: $password) {
    token
    user {
      _id
      email
    }
  }
}
`;



export const ADD_ABOUT_POST = gql`
mutation AddAboutPost($email: String!, $aboutPost: String!) {
  addAboutPost(email: $email, aboutPost: $aboutPost) {
    _id
    aboutPost
  }
}
`

export const ADD_CONTACT_INFO = gql`
mutation AddContactInfo($email: String!, $contactInfo: String!) {
  addContactInfo(email: $email, contactInfo: $contactInfo) {
    contactInfo
  }
}
`

export const ADD_BLOG_POST = gql`
mutation AddBlogPost($titleText: String!, $blogText: String!) {
  addBlogPost(titleText: $titleText, blogText: $blogText) {
    _id
    titleText
    blogText
    createdAt
  }
}
`

export const REMOVE_BLOG_POST = gql`
mutation RemoveBlogPost($blogId: ID!) {
  removeBlogPost(blogId: $blogId) {
    _id
    titleText
    blogText
    createdAt
    comments {
      _id
      commentText
      commentAuthor
      createdAt
    }
  }
}
`

export const EDIT_BLOG_POST = gql`
mutation EditBlogPost($blogId: ID!, $titleText: String!, $blogText: String!) {
  editBlogPost(blogId: $blogId, titleText: $titleText, blogText: $blogText) {
    _id
    titleText
    blogText
    createdAt
    comments {
      _id
      commentText
      commentAuthor
      createdAt
    }
  }
}
`

export const ADD_COMMENT = gql`
mutation AddComment($blogId: ID!, $commentText: String!, $commentAuthor: String!) {
  addComment(blogId: $blogId, commentText: $commentText, commentAuthor: $commentAuthor) {
    _id
    titleText
    blogText
    createdAt
    comments {
      _id
      commentText
      commentAuthor
      createdAt
    }
  }
}
`

export const REMOVE_COMMENT = gql`
mutation RemoveComment($blogId: ID!, $commentId: ID!) {
  removeComment(blogId: $blogId, commentId: $commentId) {
    _id
    titleText
    blogText
    createdAt
    comments {
      _id
      commentText
      commentAuthor
      createdAt
    }
  }
}
`