import '../App.css';
import React, { useState } from 'react';

import { useMutation } from '@apollo/client';

import { useQuery } from '@apollo/client';
import { QUERY_CONTACT_INFO } from '../utils/queries';

const Contact = () => {

    const { data } = useQuery(QUERY_CONTACT_INFO, {variables: {email: "303tacticaltraining@gmail.com"}});
    console.log(data)
    console.log(data?.user.contactInfo[0]);

    let contactInfo = data?.user.contactInfo[0]
    console.log(contactInfo);

    const [fNameState, setfNameState] = useState({ fName: '' });

    const [emailState, setEmailState] = useState({ email: '' });

    const [messageState, setMessageState] = useState({ message: '' });

    const handlefNameChange = (event) => {
        const { name, value } = event.target;

        setfNameState({
        ...fNameState,
        [name]: value,
        });
    };

    const handleEmailChange = (event) => {
        const { name, value } = event.target;

        setEmailState({
        ...emailState,
        [name]: value,
        });
    };

    const handleMessageChange = (event) => {
        const { name, value } = event.target;

        setMessageState({
        ...messageState,
        [name]: value,
        });
    };

    let bodyText = "You have received a message from" + fNameState + "at the email:" + emailState + ", in case you'd like to reply. The message is: " + messageState;

    // console.log(bodyText)

    const sendTo = () => {
    


            window.lo.href = document.getElementById('tag-id').innerHTML ="mailto:probablyannamurphy@gmail.com?subject=Test email&body=" + bodyText;
    
            alert("Success! Message sent");

//&cc='+CCvariable

    }

    return (
        <div id="content">
            <h2>Contact</h2>
        <div>
            <h3>{contactInfo}</h3>
        </div>

        <div id="contactFormDiv">

        {/* <form onSubmit={(sendTo)}>
            <label for="fname">First name:</label> <br />
            <input type="text" name="fname" onChange={handlefNameChange}/> <br />
            <label for="email">Email:</label> <br />
            <input type="text" name="email" onChange={handleEmailChange}/> <br /> 
            <label for="message">Message:</label> <br />
            <textarea name="message" rows="4" cols="50" placeholder='Enter text here...' onChange={handleMessageChange}/> <br /> 
            <button type="submit" > Submit </button>
            
        </form>  */}
            <br />
        </div>
            {/* {Newsletter()} */}
        </div>

    )
}

export default Contact;